<template>
  <v-date-picker class="date-picker__custom" :attributes="attrs" :value="value" @input="onInput" :max-date="maxDate" :min-date="minDate" :input-props='{placeholder: placeholder, required: required}' :masks="{ input: ['DD.MM.YYYY']}" />
</template>

<script lang="js">
import Vue from 'vue';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

export default Vue.extend({
  name: 'date-picker',
  components: {
    VDatePicker,
  },
  props: {
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Date],
      required: true,
    },
    mode: {
      type: String,
      default: 'single',
    },
    attrs: {
      type: Array,
      default: () => [
        {
          key: 'today',
          highlight: 'yellow',
          dates: new Date(),
        },
      ],
    },
    minDate: {
      type: [String, Date],
      default: () => new Date(),
    },
    maxDate: {
      type: [String, Date],
      default: () => {
        const duration = new Date();
        duration.setMonth(duration.getMonth() + 12);
        return duration;
      },
    },
    placeholder: {
      type: String,
      default: 'Bitte wählen',
    },
  },
  data: () => ({
    date: new Date(),
  }),
  methods: {
    onInput(date) {
      this.$emit('input', date);
    },
  },
});
</script>

<style lang="scss" scoped>
.date-picker__custom {
  input {
    border-radius: 0px;
  }
}
</style>
